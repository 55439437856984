/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    blockquote: "blockquote",
    p: "p",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "There’s a generally accepted level of accessibility which is known as RAND or resonable and non-discriminatory which tech-industries wide is expected for certain types of documentation/specification. Historically “reasonable” was understood to mean that corporate entities could reasonably afford it, and the pricing would be at values like 10 or 50 thousand dollars US."), "\n", React.createElement(_components.p, null, "These values are clearly not “reasonable” for individual contributors. And moreover in the modern era it is very questionable what that high fee is for, since the costs for information dissemination are so low."), "\n", React.createElement(_components.p, null, "As a result, there has been some beginning of adjustment for what is a reasonable pricing to fit into the RAND style labelling. It’s happening slowly, but it does seem to be occurring. ", React.createElement(_components.a, {
    href: "http://lwn.net/Articles/648735/"
  }, "k8to")), "\n"), "\n", React.createElement(_components.p, null, "El mundo antes de Internet funcionaba así: se decidía crear un estándar entre varias empresas, se creaba una alianza para debatirlo, se publicaba y se distribuía. Definir estándares no es algo que ", React.createElement(_components.em, null, "cueste"), " dinero, es más bien una negociación de carácter técnico, pero antes de internet, la creación de una alianza y la publicación y distribución del estándar desarrollado tenía un coste elevado, suponía la creación de un gran sistema burocrático con sedes en distintos países y gran cantidad de personal haciendo tareas puramente administrativas."), "\n", React.createElement(_components.p, null, "Con la red de redes todo esto ha cambiado, como bien saben en la comunidad Linux, la diseminación de información tiene un coste prácticamente cero. ¿Que sentido tiene seguir pagando las cuotas de acceso a estándares industriales? Para la gran industria la cosa está clara, mantener todo esa jerarquía burocrática, incluso crear nuevas con el mismo sistema, el objetivo es siempre el mismo, la extracción de rentas, donde los más pequeños siempre tienen las de perder. Así tenemos cosas como la ", React.createElement(_components.a, {
    href: "http://mipi.org/"
  }, "MIPI Alliance"), " o ", React.createElement(_components.a, {
    href: "https://www.khronos.org/"
  }, "The Khronos Group"), ", que podrían hacer mucho más de lo que hacen si abrieran sus puertas a las comunidades de hardware y software libres."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
